import { IToggleButtonsCell, IDeleteButtonCell } from '@/components/cells/cellInterfaces';
import { IRoseAGGridColumn } from '@rose/common-ui';
import { ITerminart, HKPTerminType } from '@rose/types';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

export function settingsHkpZuordnungColumnsDefs(): IRoseAGGridColumn<string>[] {
  return [
    {
      headerName: 'Terminart',
      field: 'bezeichnung',
      valueGetter: params => {
        if (params.data) {
          const terminart = editSettingsStore.getters.terminartenMap?.[params.data];
          return `${terminart?.bezeichnung} (${terminart?.extid})`;
        }
      },
      sort: 'asc',
      sortable: true,
      width: 350,
      resizable: true,
    },
    {
      headerName: 'Termingruppe',
      valueGetter: params => params.data && editSettingsStore.getters.terminartenMap?.[params.data].gruppe,
      field: 'gruppe',
      cellRenderer: 'TermingruppeCell',
      sortable: true,
      width: 200,
      resizable: true,
    },
    {
      headerName: 'HKP-Art',
      flex: 1,
      field: 'hkpTyp',
      valueGetter: params =>
        params.data && { key: params.data, value: editSettingsStore.getters.terminartenConfiguredHkp?.[params.data] },
      cellRenderer: 'ToggleButtonsCell',
      cellRendererParams: {
        context: {
          buttons: [
            {
              value: HKPTerminType.ZE,
              name: 'ZE',
              help: 'Für diese Terminart muss ein genemigter ZE-HKP/KV vorliegen.',
              outlined: true,
            },
            {
              value: HKPTerminType.PA,
              name: 'PAR',
              help: 'Für diese Terminart muss ein genemigter PAR-HKP/KV vorliegen.',
              outlined: true,
            },
            {
              value: HKPTerminType.IMPLA,
              name: 'IMPLA',
              help: 'Für diese Terminart muss ein genemigter Implantat KV vorliegen.',
              outlined: true,
            },
            {
              value: HKPTerminType.PRIVAT,
              name: 'Privat',
              help: 'Für diese Terminart muss ein genemigter privater KV vorliegen.',
              outlined: true,
            },
            {
              value: HKPTerminType.KFO,
              name: 'KFO',
              help: 'Für diese Terminart muss ein genemigter KFO KV vorliegen.',
              outlined: true,
            },
            {
              value: HKPTerminType.ALLE,
              name: 'Alle',
              help: 'Für diese Terminart muss irgendein genemigter HKP/KV vorliegen.',
              outlined: true,
            },
            { value: HKPTerminType.OHNE, name: 'Nicht vergeben', color: 'error', outlined: true },
          ],
          clicked(params: { key: ITerminart['extid']; value: HKPTerminType }) {
            console.log('clicked', params);
            editSettingsStore.commit.updateTerminArtHkpArt({ terminart: params.key, hkpType: params.value });
          },
        },
      } as IToggleButtonsCell<ITerminart['extid'], HKPTerminType>,
      sortable: false,
      width: 400,
    },
    {
      headerName: '',
      field: 'extid',
      cellRenderer: 'DeleteButtonCell',
      width: 40,
      cellStyle: { justifyContent: 'center' },
      cellRendererParams: {
        context: {
          clicked(field: { column: string; row: string }) {
            editSettingsStore.commit.deleteTerminartenHkp([field.row]);
          },
        },
      } as IDeleteButtonCell<any, string>,
    },
  ];
}
